<template>
  <div class="booking-page">
    <div class="breadcrumbs">
      <div class="breadcrumb">
        <router-link class="breadcrumb-link" :to="{ name: 'admin.trainings' }">Academy</router-link>
      </div>
      <div class="breadcrumb">
        <router-link class="breadcrumb-link" v-if="booking && booking.event && booking.event.training" :to="{ name: 'admin.training', params: { 'id': trainingId } }">Training: {{ getTranslation(booking.event.training).title }}</router-link>
      </div>
    </div>

    <header class="page-header">
      <PageTitle v-if="booking" :item="booking" property="company_name"/>
      <div class="buttons">
        <button v-if="booking && checkPermissions(['academy_training.edit'])" class="btn btn-save" @click="saveBooking">
          <span>{{ booking && booking.id ? 'Booking wijzigen' : 'Booking toevoegen' }}</span>
        </button>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">

        <formulate-form ref="bookingForm" name="bookingForm" v-model="booking" @submit="saveBooking">
          <h3>{{ $t('Gegevens bedrijf') }}</h3>
          <formulate-input validation="required" type="text" name="company_name" :label="$t('Bedrijf')" :wrapper-class="['lg']"></formulate-input>

          <formulate-input validation="required" type="text" name="company_address1" :label="$t('Straat en nr')"></formulate-input>
          <div class="group">
            <formulate-input validation="required" type="text" name="company_postal_code" :label="$t('Postcode')"></formulate-input>
            <formulate-input validation="required" type="text" name="company_locality" :label="$t('Gemeente')"></formulate-input>
          </div>
          <div class="group">
            <formulate-input validation="required" type="select" :options="countries" name="company_country_code" :label="$t('Land')"></formulate-input>
          </div>

          <h3>{{ $t('Gegevens contactpersoon') }}</h3>
          <div class="group">
            <formulate-input validation="required" type="text" name="contact_first_name" :label="$t('Contactpersoon voornaam')"></formulate-input>
            <formulate-input validation="required" type="text" name="contact_last_name" :label="$t('Contactpersoon achternaam')"></formulate-input>
          </div>
          <div class="group">
            <formulate-input validation="required|email" type="email" name="contact_email" :label="$t('E-mailadres')"></formulate-input>
            <formulate-input validation="required" type="tel" name="contact_telephone" :label="$t('Telefoonnummer')"></formulate-input>
          </div>

          <h3>{{ $t('Deelnemers opleiding') }}</h3>
          <formulate-input type="group" name="participants" :repeatable="true" remove-label="X" :add-label="$t('+ Voeg deelnemer toe')">
            <formulate-input name="id" type="hidden" :class="['hidden']"></formulate-input>
            <formulate-input validation="required" name="first_name" type="text" :label="$t('Voornaam')"></formulate-input>
            <formulate-input validation="required" name="last_name" type="text" :label="$t('Achternaam')"></formulate-input>
            <formulate-input validation="required" name="company_position" type="text" :label="$t('Functie')"></formulate-input>
          </formulate-input>

          <formulate-input type="textarea" name="extra_info" :label="$t('Heb je speciale vragen of opmerkingen? Allergieën voor de lunch? Laat het ons hieronder weten.')"></formulate-input>
        </formulate-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import PageTitle from '@/components/admin/PageTitle'

export default {
  components: {
    PageTitle
  },
  data () {
    return {
      booking: null,
      eventId: this.$route.params.eventId,
      trainingId: this.$route.params.trainingId,
      bookingId: this.$route.params.id,
      countries: {
        be: this.$t('België'),
        nl: this.$t('Nederland'),
        fr: this.$t('Frankrijk'),
        de: this.$t('Duitsland'),
        lu: this.$t('Luxemburg')
      }
    }
  },
  async mounted () {
    await ApiService.fetchTrainingEventBooking(this.trainingId, this.eventId, this.bookingId).then(res => {
      this.booking = res.data
    })
  },
  methods: {
    async saveBooking () {
      this.$formulate.submit('bookingForm')
      if (await this.$refs.bookingForm.hasValidationErrors()) {
        return true
      }

      this.booking.dealer_id = this.booking.dealer.id
      this.booking.event_id = this.booking.event.id
      await ApiService.patchTrainingEventBooking(this.trainingId, this.eventId, this.booking)
        .then(res => {
          if (res.status === 200) {
            notification('Booking succesvol aangepast!')
          } else if (res.response.status === 409) {
            notification(this.$tc(res.response.data.detail.message, res.response.data.detail.seats_over, { 0: res.response.data.detail.seats_over }), 'error')
          }
        })
    }
  }
}
</script>
